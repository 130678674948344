
  export default {
    props: {
      mods: {
        type: Array,
        default: []
      },
      requiredOnly: {
        type: Boolean,
        default: false
      },
      isModal: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      filteredMods() {
        if (!this.requiredOnly) {
          return this.mods;
        }

        return this.mods.filter(mod => mod.selects.mandatory || mod.selects.min);
      },
    },
  }
