
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'ProductDetails',
  data() {
    return {
      open: false,
    }
  },
  props: {
    showDetails: {
      default: true,
      type: Boolean,
    },
    isModal: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState(['location', 'cart', 'product']),
    ...mapState({
      product: state => state.product.data,
      choices: state => state.product.choices,
      isEdit: state => state.product.isEdit,
      quantity: state => state.product.quantity,
    }),
    actionText() {
      if (this.isEdit) {
        return 'Update Bag'
      } else {
        return 'Add to Order'
      }
    },
    totalCost() {
      let total = this.product.cost
      this.choices.forEach((choice,i) => {
        let qty = choice.quantity
        if (typeof qty == undefined) {
          qty = 1
        }
        total += choice.cost*choice.quantity
      })
      total = (total*this.quantity)
      return total ? total.toFixed(2) : ''
    },
    shouldDisableSubmitButton() {
      if (!this.isModal) {
        return false
      }

      const errors = this.validateMods(this.product.mods, this.choices)

      if (errors.length > 0) {
        return true
      }
      return false
    },
  },
  methods: {
    ...mapActions({
      addProduct: 'product/addProduct',
      updateProduct: 'product/updateProduct',
      removeAllMods: 'product/removeAllMods',
    }),
    ...mapMutations(['setShowCart']),
    toggle() {
      this.open = !this.open
    },
    isPizzaSide(name) {
      return (name == 'Customize Left' || name == 'Customize Right' || name == 'Customize Whole')
    },
    getSideFromChoice(choice_id) {
      let whole = false
      let left = false
      let right = false

      const sizeFound = this.product.mods.map(mod => {
        return mod.options.find(option => {
          return this.isSelected(option.choice_id)
        })
      })[0]
      if (!sizeFound) {
        return ''
      }

      sizeFound.mods.forEach(subMod => {
        if (subMod.name === 'Left') {
          left = subMod
        }
        if (subMod.name === 'Whole') {
          whole = subMod
        }
        if (subMod.name === 'Right') {
          right = subMod
        }
      })
      if (left) {
        if (JSON.stringify(left).includes(choice_id)) {
          return '(L)'
        }
      }
      if (right) {
        if (JSON.stringify(right).includes(choice_id)) {
          return '(R)'
        }
      }
      if (whole) {
        if (JSON.stringify(whole).includes(choice_id)) {
          return '(W)'
        }
      }
      return ''
    },
    isSelected(id) {
      return this.choices.find(choice => choice.id === id)
    },
    async handleClick() {
      if (this.cart) {
        if (this.$route.query && this.$route.query.edit) {
          const productFound = this.cart.products.find((p, i) => {
            return p.product_id == this.$route.query.edit && i == this.$route.query.position
          })
          if (productFound) {
            const updatedProd = {
              ...productFound,
              choices: this.choices,
              quantity: this.quantity,
            }
            const success = await this.updateProduct(updatedProd)
            if (success) {
              // return this.openCart()
              if (this.location) {
                this.$gtm.push({ ecommerce: null })
                this.$gtm.push({
                  event: "add_to_cart",
                  ecommerce: {
                    value: parseFloat(this.totalCost),
                    currency: "USD",
                    location_name: this.location.name,
                    location_id: this.location.id,
                    items: [{
                      item_id: updatedProd.id,
                      item_name: updatedProd.name,
                    }],
                  }
                })
              }
              return this.openCartOnMenu()
            }
            return
          }
        }
      }

      const success = await this.addProduct()
      if (success) {
        if (this.location) {
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: "add_to_cart",
            ecommerce: {
              value: parseFloat(this.totalCost),
              currency: "USD",
              location_name: this.location.name,
              location_id: this.location.id,
              items: [{
                item_id: this.product.id,
                item_name: this.product.name,
              }],
            }
          })
        }

        this.openCartOnMenu()
      }
    },
    openCart() {
      document.body.style.overflow = 'hidden'
      this.setShowCart(true)
    },
    openCartOnMenu() {
      document.body.style.overflow = 'hidden'
      this.setShowCart(true)
      this.$router.push(`/menu/${this.location.menu_id}`)
      this.$emit('openCartOnMenu')
    },
    validateMods(mods = [], choices, errors = []) {
      mods.map(m => {
        const mandatory = m.selects.mandatory
        const min = m.selects.min
        const max = m.selects.max
        let count = 0
        m.options.map(o => {
          const option = choices.find(c => c.id === o.choice_id)
          if(option) {
            count += option.quantity
            if(o.mods) {
              errors = this.validateMods(o.mods, choices, errors)
            }
          }
        })
        if(mandatory && !min && !max && count !== 1) {
          errors.push(`${m.name} is required.`)
        } else if(count < min) {
          errors.push(`${m.name} requires a minimum of ${min} selections.`)
        }
      })
      
      return errors
    }
  },
}
