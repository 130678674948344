
  import { mapState, mapMutations } from 'vuex'

  export default {
    computed: {
      ...mapState({
        product: state => state.product?.data,
        quantity: state => state.product.quantity,
      }),
      images() {
        return (this.product && this.product.images) ? this.product.images : null
      },
      imageUrl() {
        if (this.images) {
          if(this.images && this.images['marketplace-product']) {
            return this.images['marketplace-product'].url
          }
          if(this.images && this.images.default) {
            return this.images.default.url
          }
        }
        return ''
      },
      correctedNutritionLifestyle() {
        // Handle typo, nutrition_lifestyle can come as 'nutrition_lifestyle ', with a trailing space
        return this.product.nutrition_lifestyle || this.product['nutrition_lifestyle '];
      }
    },
    methods: {
      ...mapMutations({
        setQuantity: 'product/setQuantity',
      }),
      calories(product) {
        let base = product.nutrition?.calories?.base
        let max = product.nutrition?.calories?.max
        // if (base) {
        //   base = base.toString().replace('-','')
        // }
        // if (max) {
        //   max = max.toString().replace('-','')
        // }
        return (product.cost && base !== null  ? ' | ' : '') + (base !== null ? base : '') + (max !== null ? ' - '+max : '') + (base !== null ? ' Cal' : '')
      },
      subtract() {
        this.setQuantity(this.quantity - 1)
      },
      add() {
        this.setQuantity(this.quantity + 1)
      },
    }
  }
