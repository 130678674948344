
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        scrolledProductModifiers: [],
      }
    },
    computed: {
      ...mapState([ 'location', 'cart' ]),
      ...mapState({
        cart: state => state.cart,
        product: state => state.product?.data,
        choices: state => state.product.choices,
        quantity: state => state.product.quantity,
      }),
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      },
      images() {
        return (this.product && this.product.images) ? this.product.images : null
      },
      imageUrl() {
        if (this.images) {
          if(this.images && this.images['marketplace-product']) {
            return this.images['marketplace-product'].url
          }
          if(this.images && this.images.default) {
            return this.images.default.url
          }
        }
        return ''
      }
    },
    destroyed() {
      document.body.style.overflow = 'auto'
    },
    beforeMount() {
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'view_item',
        currency: 'USD',
        value: this.product.cost * this.quantity,
        location_code: this.location.id,
        location_name: this.location.name,
        items: [
          {
            item_name: this.product.name,
            item_id: this.product.product_id,
            price: this.product.cost,
          }
        ],
      })
    },
    methods: {
      goToProduct() {
        document.body.style.overflow = 'auto'
        this.$router.push('/menu/'+this.location.menu_id+'/product/'+this.product.id)
      },
      openCartOnMenu() {
        this.$emit('hideQuickAddOpenCart')
      },
      hideQuickAdd() {
        this.$emit('hideQuickAdd')
      },
    },
    watch: {
      choices() {
        if (this.choices.length > 0) {
          this.$nextTick(() => {
            const productModifiers = Array.from(this.$refs.quickAddModal.querySelectorAll('.product-modifier'));
            // Get the first product modifier that is not in the scrolledProductModifiers array (one that we haven't scrolled to yet)
            const productModifierElement = productModifiers.find(modifier => !this.scrolledProductModifiers.includes(modifier));

            if (!productModifierElement) return

            const productModifierHeading = productModifierElement.querySelector('h3');
            if (!productModifierHeading) return

            productModifierHeading.scrollIntoView({ behavior: 'smooth', block: 'start' })
            this.scrolledProductModifiers.push(productModifierElement)
          })
        }
      }
    }
  }
