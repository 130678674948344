import { render, staticRenderFns } from "./ProductDetails.vue?vue&type=template&id=a86cecdc"
import script from "./ProductDetails.vue?vue&type=script&lang=js"
export * from "./ProductDetails.vue?vue&type=script&lang=js"
import style0 from "./ProductDetails.vue?vue&type=style&index=0&id=a86cecdc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconHandoffArrow: require('/opt/build/repo/components/icons/IconHandoffArrow.vue').default,IconTrash: require('/opt/build/repo/components/icons/IconTrash.vue').default})
